.common {
  display: flex;
  flex-direction: row;
  gap: 10px;
  text-align: left;
  background-color: #f9efec;
  padding: 10px 10% 10px 10%;
  box-sizing: border-box;
  font-size: 0.8em;
  float: left;
  min-height: 100%;
  width: 100%;
}
.other {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.fotoContactInformation {
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: 300px;
}
.foto-wrapper {
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.Foto {
  width: 300px;
  height: 400px;
  border: 1px solid #e0dad9;
  border-radius: 4px;
  background-color: white;
  padding: 15px;
  object-fit: cover;
  box-sizing: border-box;
}
.foto-wrapper:hover .backgroundOfFoto {
  background-color: #421a0b75;

  opacity: 1;
}
.backgroundOfFoto {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  transition: 0.3s ease;
  opacity: 0;
  text-align: center;
  display: flex;
}
.fa-upload {
  text-align: center;
  color: white;
  display: inline-block;
  font-size: 50px;
  margin: auto;
}
.inputFoto {
  display: none;
}

.list {
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 0;
}

li {
  margin-bottom: 10px;
}
.PersonalInformation {
  background-color: white;
  padding: 10px;
  border: 1px solid #e0dad9;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
}
.third {
  background-color: white;
  padding: 10px;
  border: 1px solid #e0dad9;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
}

span {
  font-weight: bold;
}
h3 {
  margin-top: 0;
}
h2 {
  margin-top: 0;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@media (min-width: 100px) and (max-width: 600px) {
  .common {
    flex-direction: column;
    padding: 5px 5% 5px 5%;
  }
  .Foto {
    width: 100%;
    height: 300px;
  }
  .fotoContactInformation {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
}

.personal-information-edit-button,
.personal-information-save-button {
  font-size: 1.6em;
  color: rgb(255, 115, 0);
  border: 1px solid rgb(209, 209, 209);
  border-radius: 2px;
  padding: 5px;
  box-sizing: border-box;
  position: absolute;
  cursor: pointer;
}
.personal-information-edit-button {
  top: 3px;
  right: 3px;
}
.personal-information-save-button {
  top: 3px;
  right: 30px;
}
