.add-stories__component {
  background-color: #f9efec;
  border: 1px solid grey;
  border-radius: 10px;
  margin: 7px;
  .add-stories__form {
    display: flex;
    flex-direction: column;
    border: 0;
  }
}

.add-stories__form {
  margin: 10px;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 7px;
}
.submit-button__form {
  width: 100px;
  border-radius: 5px;
  border: 0;
  background-color: #a96045;
  color: white;
  margin-top: 10px;
}
.submit-button__form:hover {
  background-color: rgb(255, 139, 0);
  cursor: pointer;
}
.admin-field__edit {
  margin-top: 40px;
}
