.main-form {
  width: 600px;
  height: 600px;
  background-color: #a96045;
  border-radius: 10px;
  margin: 30px auto;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  box-sizing: border-box;
}
.login-component {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 30px auto;
}

.sing-up,
.login {
  width: 250px;
  height: 60px;
  display: flex;
  background-color: #632711;
}

.active {
  background-color: rgb(255, 139, 0);
}

.sing-up {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.login {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.wrapper-main {
  text-align: center;
  color: white;
  margin: auto;
}
.form {
  width: 300px;
  margin: 60px auto 30px;
  padding: 10px;
  position: relative;
}
.signupOrlogin {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.icon-login {
  width: 70px;
  color: #a96045;
  margin: auto;
  display: inline-block;
}
.field {
  width: 100%;
  max-width: 290px;
  height: 40px;
  background-color: white;
  color: #a96045;
  font-size: 1.125rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 10px;
  cursor: pointer;
  margin: 10px auto;
}
.wrapper {
  height: 100%s;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #a96045;
}
.put-information {
  background-color: transparent;
  height: 40px;
  border: none;
  width: 100%;
  padding-left: 10px;
  box-sizing: border-box;
}
.put-information :active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}
.submit-button {
  cursor: pointer;
  background-color: rgb(255, 139, 0);
  border: none;
  border-radius: 10px;
  width: 250px;
  height: 60px;
  color: white;
  font-weight: bold;
  font-size: 16px;
  font-family: "Montserrat";
  margin-top: 35px;
}

@media (min-width: 100px) and (max-width: 600px) {
  .signupOrlogin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .sing-up,
    .login {
      border-radius: 10px;
      margin: auto;
    }
  }
  .main-form {
    width: 100%;
  }
}
.authCheckBox-wrapper,
.auth-page__title {
  color: white;
  font-size: 1.125rem;
}
.auth-page__title {
  font-weight: 600;
}
