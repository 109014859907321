.imagesPage__component {
  height: 100%;
  background-color: white;
}

.images-preview {
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  .admin-page-images_image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    padding: 5px;
    border: 1px solid gray;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: white;
  }
}

.list-of-Images {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  background-color: white;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  align-content: baseline;
  .admin-page-images_image {
    width: 250px;
    height: 250px;
    object-fit: cover;
    padding: 5px;
    border: 1px solid gray;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: white;
  }
}

h1 {
  margin-top: 10px auto;
  color: rgb(93, 27, 3);
}
.input-file {
  display: none;
}
.inputDesign {
  width: 100%;
  max-width: 290px;
  height: 60px;
  background: rgb(193 93 57);
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 3px;
  cursor: pointer;
  margin: 10px auto;
}
.icon-wrapper {
  height: 60px;
  width: 60px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #fff;
}
.icon {
  font-size: 1.5em;
}
.admin-images_icon {
  font-size: 40px;
}
.admin-page-images_wrapper {
  font-size: 40px;
  position: absolute;
  bottom: 9px;
  padding: 0 6px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.admin-page-images_mainwrapper {
  position: relative;
}
.admin-images_button {
  color: rgb(93, 27, 3);
}
.admin-images_button:hover {
  cursor: pointer;
  color: white;
  background-color: rgb(193 93 57);
  border: 0;
  transition: 0.2s ease;
}
