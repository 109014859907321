.stories-page__component {
  background-color: #f9efec;
  display: flex;
  flex-direction: column;
  padding: 10px 10% 10px 10%;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}

@media (min-width: 100px) and (max-width: 600px) {
  .stories-page__component {
    padding: 0 10px;
    box-sizing: border-box;
  }
}

.storiesPage-list {
  margin: 10px auto;
  width: 1000px;
  padding-top: 30px;
  background-color: white;
  border: 1px solid #e0dad9;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
}

.storiesPage-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.storiesPage-date {
  // margin: auto 0;
  line-height: 2.5em;
  color: gray;
  font-size: 0.8em;
}
.storiesPage-title {
  max-width: 80%;
  line-height: 1em;
  font-size: 1.6em;
  font-weight: 700;
  text-align: left;
}
.storiesPage-description {
  text-align: left;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.storiesPage-iframe__wrapper {
  width: 100%;
  height: 600px;
  iframe {
    width: 100%;
    height: 100%;
  }
}
.storiesPage-share {
  text-align: right;
  padding-top: 30px;
  box-sizing: border-box;
}
.storiesPage__button {
  text-transform: uppercase;
  font-weight: bold;
  background-color: #a96045;
  color: white;
  width: 150px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 300;
  transition: 0.2s ease;
  padding: 3px;
}
.storiesPage-element {
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.storiesPage__button:hover {
  background-color: rgb(255, 139, 0);
  cursor: pointer;
}
