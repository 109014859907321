.icon-personal {
  border: none;
  color: rgb(209, 207, 207);
}
.icon-personal:hover {
  color: rgb(255, 139, 0) !important;
  cursor: pointer;
}
.fa-pencil {
  color: white;
  border: none;
}
.third:hover {
  .fa-pencil {
    color: rgb(209, 207, 207);
    border: none;
  }
}
.fa-pencil:hover {
  color: rgb(255, 139, 0);
  cursor: pointer;
}
.personal-information__select {
  border-radius: 10px;
  border: 1px solid #bdbdbd;
  width: 150px;
  height: 100px;
  font-size: 0.8rem;
  font-weight: 400;
  color: #212529;
  padding: 0.375rem 0.75rem;
}

.attention {
  text-align: center;
  color: rgb(179, 2, 2);
  font-weight: bold;
  font-size: 1.2em;
}
