.slider-component {
  width: 100%;
  height: 800px;
  object-fit: cover;
  background-size: cover;
  background-position: bottom;
  display: flex;
  position: relative;
  overflow: hidden;
}

.slider-title {
  color: white;
  font-size: 6em;
  position: absolute;
  margin: 0 auto;
  top: 150px;
  width: 100%;
  height: 230px;
  text-align: center;
  display: block;
  line-height: 1em;
  font-weight: bold;
  background: #2e2f45;
  mix-blend-mode: soft-light;
  -webkit-text-stroke: 0.5px white;
}

.slider-title2 {
  color: transparent;
  font-size: 6em;
  position: absolute;
  margin: 0 auto;
  top: 150px;
  width: 100%;
  height: 230px;
  text-align: center;
  display: block;
  line-height: 1em;
  font-weight: bold;
  -webkit-text-stroke: 0.4px white;
  // text-shadow: 0 0 2px white;
  // background: #2e2f45;
  // mix-blend-mode: overlay;
}

.slider-statistics {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 150px;
  padding: 0 10%;
  box-sizing: border-box;
  color: white;
}

.statistic-element {
  display: flex;
  flex-direction: row;
  position: relative;
  transition: 0.3s ease;
  cursor: pointer;
}

.statistic-element:hover {
  transform: scale(120%);
}

.statistic-element:hover .stat-number {
  color: #eb5119;
}

.stat-number {
  font-size: 7em;
  font-weight: bold;
  color: aqua;
  color: #c65e37;
  text-shadow: 0 0 2px rgb(0, 0, 0);
  transition: 0.3s ease;
}

.stat-text {
  position: relative;
  bottom: 0;
  text-transform: uppercase;
  margin: 85px 0 0 0;
  padding: 0 0 0 10px;
  font-size: 1.2em;
}
@media (min-width: 100px) and (max-width: 600px) {
  .slider-statistics {
    display: flex;
    flex-direction: column;
  }
  .statistic-element {
    flex-direction: column;
    margin: auto;
  }
  .slider-title {
    font-size: 2.8em;
    top: 110px;
    height: auto;
    padding: 10px 0;
  }
  .stat-text {
    margin: 0;
  }
}
