.blog-component {
  display: flex;
  padding: 20px 0;
  box-sizing: border-box;
}

.blogs-list {
  margin: auto;
  width: 1000px;
  padding-top: 30px;
  gap: 20px;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.blogs-list-grid {
  display: grid;
  grid-template-columns: calc(50% - 12px) calc(50% - 12px);
  gap: 20px;
  margin: auto;
  width: 1100px;
  padding-top: 30px;
  flex-direction: row;
}

.blog-description {
  // width: 30%;
  // padding-left: 10px;
  padding-bottom: 10px;
}
.blog-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.blog-date {
  // margin: auto 0;
  line-height: 2.5em;
  color: gray;
  font-size: 0.8em;
}
.blog-title {
  max-width: 80%;
  line-height: 1em;
  font-size: 1.6em;
  font-weight: 700;
  text-align: left;
}
.blog-text {
  text-align: left;
}

.blog-video {
  width: 100%;
  height: 600px;
  iframe {
    width: 100%;
    height: 100%;
  }
}
.blog-share {
  text-align: right;
}
.blog-share__button {
  font-size: 0.8em;
  text-transform: uppercase;
  font-weight: bold;
}
.blog-element {
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid rgb(221, 221, 221);
}

@media (min-width: 100px) and (max-width: 600px) {
  .blog-component {
    padding: 0 10px;
    box-sizing: border-box;
  }
  .blogs-list-grid {
    grid-template-columns: 100%;
  }
}
