.o-textarea__default-style {
  width: 320px;
  height: 200px;
  border-radius: 10px;
  border: 1px solid #bdbdbd;
  display: block;
  padding-left: 7px;
  padding-bottom: 5px;
  box-sizing: border-box;
}
.o-textarea__title {
  padding: 3px 10px;
  width: 300px;
  text-align: left;
  font-weight: 500;
  color: #7a2a0c;
  font-size: 10px;
  box-sizing: border-box;
}
