.add-stories__form {
  margin: 10px;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 7px;
  display: block;
}
.pages-articles__img {
  display: block;
  border-radius: 10px;
  width: 320px;
  height: 200px;
  padding-top: 5px;
  box-sizing: border-box;
}
.pages-articles_buttonDelete {
  width: 100px;
  border-radius: 5px;
  border: 0;
  background-color: #f9521a;
  color: white;
  margin-top: 10px;
}
.pages-areticles__editArticles {
  padding: 10px;
  background-color: rgb(251, 247, 242);
}
.pages-articles_buttonDelete:hover {
  background-color: rgb(255, 139, 0);
  cursor: pointer;
}
