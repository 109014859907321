.article-page__component {
  text-align: left;
  background-color: #f9efec;
  padding: 10px 10% 10px 10%;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}
.article-page__component__article-wrapper {
  background-color: white;
  padding: 20px;
  border: 1px solid #e0dad9;
  border-radius: 4px;
  box-sizing: border-box;
  padding-top: 20px;
  max-width: 1000px;
  margin: 0 auto;
  min-height: 600px;
}
.article-page__title-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  width: 100%;
}
.article-page__title {
  padding-right: 20px;
  padding-left: 20px;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 20px;
  color: #7a2a0c;
}
.article-page__date {
  font-size: 14px;
  box-sizing: border-box;
  font-weight: 300;
  width: 200px;
  text-align: right;
  margin: 0;
  padding-right: 20px;
  box-sizing: border-box;
}
.article-page__image {
  width: 40%;
  height: 40%;
  border-radius: 4px;
  object-fit: cover;
  float: right;
  margin: 7px 0 7px 7px;
}
.article-page__description {
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
}
.article-page__text {
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}
@media (min-width: 100px) and (max-width: 600px) {
  .article-page__image {
    width: 100%;
  }
  .article-page__title-wrapper {
    flex-direction: column;
    justify-content: space-between;
  }
  .article-page__date {
    margin: 0 auto;
    padding-top: 10px;
  }
}
