h2 {
  line-height: 1;
  font-size: 20px;
  padding-left: 20px;
  box-sizing: border-box;
}
.articles-page__component {
  height: 100%;
}
.main-article {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: left;
  background-color: #f9efec;
  padding: 10px 10% 10px 10%;
  box-sizing: border-box;
  font-size: 0.8em;
  float: left;
  width: 100%;
  min-height: 100%;
}
.article-articles {
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
}
.article-entire {
  background-color: white;
  padding: 10px;
  border: 1px solid #e0dad9;
  border-radius: 4px;
  box-sizing: border-box;
  padding-top: 20px;
  max-width: 1000px;
  margin: auto;
}
.article-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  width: 100%;
}
.article-date {
  font-size: 14px;
  box-sizing: border-box;
  font-weight: 300;
  width: 200px;
  text-align: right;
  margin: 0;
  padding-right: 20px;
  box-sizing: border-box;
}
.article-name {
  padding-right: 20px;
  box-sizing: border-box;
}
.article-image {
  width: 100%;
  border-radius: 4px;
  padding: 10px;
  object-fit: cover;
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
}
.article-prereading {
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  font-size: 14px;
}
.button {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 300;
  width: 200px;
  margin: 0;
  box-sizing: border-box;
  width: 100px;
  background-color: #a96045;
  border: 0;
  color: white;
  border-radius: 3px;
  transition: 0.2s ease;
}
.button-wrapper {
  max-width: 100%;
  text-align: right;
  padding-right: 20px;
  margin: 0;
  box-sizing: border-box;
}
.button:hover {
  background-color: rgb(255, 139, 0);
  cursor: pointer;
}
@media (min-width: 100px) and (max-width: 600px) {
  .main-article {
    padding: 10px;
  }
}
