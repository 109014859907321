.admin-page__component {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
}

.admin-page__leftPanel {
  width: 300px;
  background-color: #854934;
  color: white;
  padding: 20px 0;
  box-sizing: border-box;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding: 15px 0;
      a {
        text-decoration: none;
        color: white;
      }
      a:hover {
        font-weight: bold;
      }
    }
  }
}

.admin-page__content {
  width: 100%;
  height: 100%;
  overflow: auto;
}
