.articles-component {
  width: 100%;
  min-height: 400px;
  background-color: #f9efec;
  padding: 50px;
  box-sizing: border-box;
}

.articles-grid {
  display: grid;
  grid-template-columns: calc(50% - 20px) calc(50% - 20px);
  gap: 40px;
  box-sizing: border-box;
}

.one-article-grid {
  display: grid;
  grid-template-columns: calc(100% - 20px);
  gap: 40px;
  box-sizing: border-box;
}

@media (min-width: 100px) and (max-width: 600px) {
  .articles-grid {
    display: flex;
    flex-direction: column;
  }
  .articles-component {
    padding: 40px 10px;
  }
}

.article-element {
  width: 100%;
  min-height: 500px;
  background-size: cover;
  background-position: center;
  transition: 0.5s ease;
  position: relative;
  filter: grayscale(70%);
  border-radius: 0px;
  padding: 20px;
  box-sizing: border-box;
  border: 20px solid white;
  box-shadow: 0px 0px 3px gray;
  color: white;
  text-shadow: 0 0 2px black;
}
.article-shadow {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: black;
  opacity: 0.3;
  z-index: -1;
  transition: 0.5s;
}
.article-element:hover {
  box-shadow: 0px 3px 15px gray;
  filter: grayscale(0%);
  transform: rotate(-1deg) translateX(-10px) scale(105%);
}
.article-element:hover .article-shadow {
  opacity: 0;
}
.article-component_title {
  font-size: 2.5em;
  font-weight: bold;
  transition: 0.5s;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.308);
}

.article-description {
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.308);
  padding: 20px 0;
}

.article-footer {
  position: absolute;
  bottom: 10px;
  right: 20px;
  font-size: 0.8em;
  text-shadow: 0 0 2px black;
}
