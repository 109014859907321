.hidden {
  display: none !important;
}

.oInput-label {
  width: 100%;
}

.input-title-label__text {
  padding: 3px 10px;
  width: 300px;
  text-align: left;
  font-weight: 500;
  color: #7a2a0c;
  font-size: 10px;
  box-sizing: border-box;
}

.input-field__input {
  display: block;
  width: 300px;
  height: calc(15px + 2px);
  padding: 0.375rem 0.75rem;
  font-family: inherit;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #bdbdbd;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
