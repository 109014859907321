html,
body {
  width: 100%;
  min-width: fit-content;
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*::-webkit-scrollbar,
html *::-webkit-scrollbar {
  height: 12px;
  width: 6px;
}

*::-webkit-scrollbar-track,
html *::-webkit-scrollbar-track {
  background: rgb(235, 235, 235);
}

*::-webkit-scrollbar-thumb,
html *::-webkit-scrollbar-thumb {
  background-color: rgb(151, 84, 45);
  border-radius: 2px;
  border: 1px solid rgb(235, 235, 235);
}
