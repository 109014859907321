.add-stories__form {
  margin: 0 auto;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 7px;
  text-align: left;
  box-sizing: border-box;
}
.personalInformation-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.profession {
  margin: 20px;
}
.profession_common {
  text-align: center;
}
.pages-profession_buttonDelete {
  width: 70px;
  border-radius: 5px;
  border: 0;
  background-color: #7a2a0c;
  color: white;
  margin-top: 10px;
  margin: 5px;
}
.pages-profession_buttonDelete:hover {
  background-color: rgb(255, 139, 0);
  cursor: pointer;
}
