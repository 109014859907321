table {
  border-spacing: 0;
  text-align: center;
  margin: auto;
  font-size: 12px;
}
thead {
  color: white;
}
.headerOfTable {
  background-color: #a96045;
  font-size: 14px;
}
th,
td {
  border-style: solid;
  border-width: 0 1px 1px 0;
  border-color: white;
}
th:first-child,
td:first-child {
  text-align: center;
}

td {
  padding: 5px;
  background: #ffe9d9;
}
tr td:last-child {
  border-right: none;
}
.buttonUsers {
  border-radius: 3px;
  border: none;
  color: white;
  background-color: #e1a690;
}
.toBlock:hover {
  background-color: red;
}
.toDo:hover {
  background-color: green;
}
