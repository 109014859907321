.first-description-block {
  width: 100%;
  min-height: 800px;
  background: linear-gradient(to right, rgb(193, 93, 57), rgb(83, 41, 42));
  display: flex;
  flex-direction: column;
}

.main_text-block {
  max-width: 1000px;
  padding: 30px 0;
  margin: auto;
  color: white;
  font-size: 2em;
  text-align: justify;
  font-style: italic;
  font-weight: 200;
  box-sizing: border-box;
}

@media (min-width: 100px) and (max-width: 600px) {
  .main_text-block {
    padding: 10px;
  }
}

.main_sigh-block {
  text-align: right;
  color: white;
  font-size: 0.6em;
  max-width: 1000px;
  font-weight: 400;
  margin: auto;
  padding-top: 20px;
}

.sight-block_text {
  text-align: right;
  max-width: 500px;
  display: inline-block;
}
