.header-component {
  width: 100%;
  height: 60px;
  background: linear-gradient(to right, rgb(83 41 42), rgb(193 93 57));
  line-height: 100%;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 0 60px;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  box-shadow: 0px 0px 4px #28110c;
  z-index: 100;
}

._margin {
  width: 100%;
  height: 100%;
  margin-bottom: 60px;
}

.hide {
  display: none;
}

.header-list-container {
  position: relative;
  height: 100%;
  display: flex;
}
.logo-image {
  height: 100%;
}
.logo {
  padding: 5px 0;
  box-sizing: border-box;
  margin: auto 0;
  height: 100%;
}
.element {
  padding: 0 10px;
  box-sizing: border-box;
  list-style: none;
  margin: auto;
  cursor: pointer;
  width: 150px;
  transition: 0.15s ease;
  text-decoration: none;
  color: white;
  a {
    text-decoration: none;
    color: white;
  }
}
.element:hover {
  font-weight: bold;
}

.userProfile {
  cursor: auto;
  font-weight: bold;
}

@media (min-width: 100px) and (max-width: 600px) {
  .header-list {
    display: flex;
    height: 100%;
    width: 100%;
    background: linear-gradient(to right, rgb(83 41 42), rgb(193 93 57));
    position: fixed;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-size: 2em;
    flex-direction: column;
    transition: 0.3s ease;
    padding: 20% 0;
    box-sizing: border-box;
  }

  .header-list-button {
    display: inline-block;
    font-size: 3em;
    margin: auto;
    z-index: 1;
  }

  .hide {
    left: 100%;
  }

  .show {
    left: 0;
  }

  .element {
    width: auto;
  }
}

@media (min-width: 600px) {
  .header-list {
    display: flex;
    height: 100%;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-size: 0.8em;
  }
  .header-list-button {
    display: none;
    right: 100%;
  }
  .hide {
    display: flex;
  }
}
